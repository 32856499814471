import React from "react";
import NavFooter from "../../components/NavFooter/NavFooter.jsx";

const Footer = () => {
  return (
    <>
      <NavFooter />
    </>
  );
};

export default Footer;
